import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Date from "../components/date"
import { Alert, Button } from "react-bootstrap"

const NAACPage = () => (
  <Layout pageInfo={{ pageName: "NAAC" }}>
    <SEO title=" Department of Chemistry BNMU NAAC" />
    <h1>NAAC</h1>
    <br />

    <div>
      <Alert variant="info">
        <Alert.Heading>One Day Workshop on Intellectual Property Rights(IPR) - Report</Alert.Heading>
        <p>22.09.2021 — Workshop.</p>
        <hr />
        <p className="mb-0">
          <a href="../documents/naac/REPORT_IPRBNMU2021.pdf">
            <Button variant="outline-dark" size="lg" block>
            Download Report (PDF)
            </Button>
          </a>
        </p>

        
      </Alert>
    </div>

    <br></br>
    <Date />
  </Layout>
)

export default NAACPage
